import { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  AVAILABLE_LANGAUGES,
  DEFAULT_LANGAUGE,
  ERROR_PAGE_URL,
  FORM_URL,
  LANGUAGE_SEARCH_KEY,
  METADATA
} from '../constants';
const MainPage: FC = () => {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();

  const language = useMemo(() => {
    const lang = searchParams.get(LANGUAGE_SEARCH_KEY) ?? DEFAULT_LANGAUGE;

    return AVAILABLE_LANGAUGES.includes(lang) ? lang : DEFAULT_LANGAUGE;
  }, [searchParams]);

  let src = 'https://vcb-volvo.hyperportal.org';

  if (pathname.startsWith(FORM_URL)) {
    src += `${pathname}${search}`;
  } else {
    src += ERROR_PAGE_URL;
  }

  return (
    <>
      <Helmet>
        <title>{METADATA.title[language]}</title>

        <meta name="description" content={METADATA.description[language]} />
      </Helmet>

      <iframe title="Form" src={src} />
    </>
  );
};

export default MainPage;
