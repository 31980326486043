export const AVAILABLE_LANGAUGES = ['nl', 'fr'];

export const DEFAULT_LANGAUGE = 'nl';
export const LANGUAGE_SEARCH_KEY = 'l';

export const FORM_URL = '/form/';
export const ERROR_PAGE_URL = `${FORM_URL}/500`;

export const METADATA = {
  title: {
    nl: 'Contact Volvo Fleet- en bedrijfswagens | Volvo Car België',
    fr: 'Contact Voitures de fleet et de société | Volvo Car Belgique'
  },
  description: {
    // eslint-disable-next-line max-len
    nl: `Van het ideale model tot het bekijken van de TCO en uw voordelen. Onze collega's helpen u graag om de juiste beslissing te maken voor uw wagenpark of bedrijfswagen.`,
    // eslint-disable-next-line max-len
    fr: `Du modèle idéal à l'examen du coût total de possession et de vos avantages. Nos collègues seront heureux de vous aider à prendre la bonne décision pour votre flotte ou votre véhicule commercial. décision pour votre flotte ou votre véhicule commercial.`
  }
};
