import ErrorBoundary from 'components/ErrorBoundary';
import MainPage from 'pages/MainPage';
import { FC, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

export const App: FC = () => {
  return (
    <HelmetProvider>
      <Suspense>
        <BrowserRouter>
          <ErrorBoundary>
            <Routes>
              <Route path="*" element={<MainPage />} />
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>
      </Suspense>
    </HelmetProvider>
  );
};
